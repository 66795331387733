import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/landing/main_list_of_logos.png'
import _imports_1 from '../assets/landing/variants/corporate-lunch-delivery-header.png'
import _imports_2 from '../assets/landing/restaurant_cards/wade_and_labour_logo.png'
import _imports_3 from '../assets/landing/restaurant_cards/banh_wagon_logo.png'
import _imports_4 from '../assets/landing/restaurant_cards/emmeline_logo.png'
import _imports_5 from '../assets/landing/restaurant_cards/chido_wey_logo.png'
import _imports_6 from '../assets/landing/restaurant_cards/monkey_vs_gods_logo.png'
import _imports_7 from '../assets/landing/restaurant_cards/the_dumpling_tree_logo.png'
import _imports_8 from '../assets/landing/restaurant_cards/tzatziki_logo.png'
import _imports_9 from '../assets/landing/restaurant_cards/taste_of_cambridge_logo.png'
import _imports_10 from '../assets/landing/restaurant_cards/hot_numbers_logo.png'
import _imports_11 from '../assets/landing/restaurant_cards/boxed_events_logo.png'
import _imports_12 from '../assets/landing/carousel_left.png'
import _imports_13 from '../assets/landing/carousel_right.png'
import _imports_14 from '../assets/landing/variants/corporate-lunch-delivery-divider.png'
import _imports_15 from '../assets/landing/divider_list_of_logos.png'
import _imports_16 from '../assets/landing/divider_list_of_logos_mobile.png'
import _imports_17 from '../assets/order_tracking/message.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "header_section" }
const _hoisted_3 = { class: "header_section_body" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "lunch_options_section" }
const _hoisted_6 = { class: "sub_title" }
const _hoisted_7 = { class: "divider_contact" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_TickerTape = _resolveComponent("TickerTape")!
  const _component_WeSupportLocal = _resolveComponent("WeSupportLocal")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Slide = _resolveComponent("Slide")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_Carousel = _resolveComponent("Carousel")!
  const _component_ThePerfectFood = _resolveComponent("ThePerfectFood")!
  const _component_BuiltForEase = _resolveComponent("BuiltForEase")!
  const _component_SustainableAndEthical = _resolveComponent("SustainableAndEthical")!
  const _component_HowItWorks = _resolveComponent("HowItWorks")!
  const _component_EverybodyWins = _resolveComponent("EverybodyWins")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, "Corporate Lunch Delivery in " + _toDisplayString(_ctx.cityName), 1),
        _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "sub_title" }, "• Elevate your office food with outstanding corporate lunch delivery from Foodstuff", -1)),
        _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "sub_title" }, "• Team meetings to company events, we’re the experts in office lunch catering", -1)),
        _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "sub_title" }, "• From buffets and grazing tables to individual office meals, we can do it all", -1)),
        _createVNode(_component_app_button, {
          class: "order_button",
          slim: "",
          onClick: _ctx.orderNow
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IconServices, { icon: "upload" }),
            _cache[1] || (_cache[1] = _createElementVNode("p", null, "Show me food", -1))
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createElementVNode("p", {
          class: "how-does-it-work-question custom-cursor-hover",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.scrollToHowItWorks && _ctx.scrollToHowItWorks(...args)))
        }, "How does it work?"),
        _cache[5] || (_cache[5] = _createElementVNode("p", { class: "quote" }, [
          _createTextVNode(" “"),
          _createElementVNode("b", null, "I had a number of people declare it was the best office lunch they’d ever had so you’ve got a big tick from me!"),
          _createTextVNode("” - Adder Technology ")
        ], -1)),
        _cache[6] || (_cache[6] = _createElementVNode("img", {
          id: "main_list_of_logos",
          src: _imports_0,
          alt: ""
        }, null, -1))
      ]),
      _cache[7] || (_cache[7] = _createElementVNode("img", {
        class: "header_image",
        src: _imports_1,
        alt: ""
      }, null, -1))
    ]),
    _createVNode(_component_TickerTape),
    _createElementVNode("div", _hoisted_5, [
      _cache[21] || (_cache[21] = _createElementVNode("h1", { class: "section_title" }, "Popular corporate lunch options", -1)),
      _createElementVNode("h2", _hoisted_6, [
        _cache[8] || (_cache[8] = _createTextVNode(" From sandwiches and salads to burritos and pizza ")),
        _createVNode(_component_WeSupportLocal, {
          color: _ctx.ham,
          width: 100,
          height: 100
        }, null, 8, ["color"])
      ]),
      _createVNode(_component_Carousel, {
        class: "occasions_carousel",
        breakpoints: _ctx.breakpoints,
        "wrap-around": true
      }, {
        addons: _withCtx(() => [
          _createVNode(_component_Navigation, null, {
            prev: _withCtx(() => _cache[19] || (_cache[19] = [
              _createElementVNode("img", {
                class: "carousel_nav_arrow",
                src: _imports_12,
                alt: ""
              }, null, -1)
            ])),
            next: _withCtx(() => _cache[20] || (_cache[20] = [
              _createElementVNode("img", {
                class: "carousel_nav_arrow",
                src: _imports_13,
                alt: ""
              }, null, -1)
            ])),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          (_ctx.cityName === 'Bristol')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "wade_and_labour" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Office lunches",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createElementVNode("div", { class: "card_image wade_and_labour" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_2,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Wade & Labour"),
                        _createElementVNode("p", null, "Seriously iconic bagels"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Bagels")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Bristol')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "banh_wagon" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Office lunches",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createElementVNode("div", { class: "card_image banh_wagon" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_3,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Banh Wagon"),
                        _createElementVNode("p", null, "Fresh Vietnamese flavour"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Bánh mì"),
                          _createElementVNode("p", null, "Rice bowls")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Bristol')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "emmeline" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Office lunches",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createElementVNode("div", { class: "card_image emmeline" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_4,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Emmeline"),
                        _createElementVNode("p", null, "BANG tidy grilled cheese!"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Grilled Cheese"),
                          _createElementVNode("p", null, "Fresh juices")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Bristol')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "chido_wey" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Office lunches",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createElementVNode("div", { class: "card_image chido_wey" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_5,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Chido Wey"),
                        _createElementVNode("p", null, "Cali-Mex realness"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Burritos & Botanas"),
                          _createElementVNode("p", null, "Vegan options")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Bristol')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "monkey_vs_gods" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Office lunches",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createElementVNode("div", { class: "card_image monkey_vs_gods" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_6,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Monkey Vs Gods"),
                        _createElementVNode("p", null, "The ultimate Asian fusion"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Asian"),
                          _createElementVNode("p", null, "Noodles, Buns and sides")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Cambridge')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "the_dumpling_tree" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Office lunches",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[14] || (_cache[14] = [
                      _createElementVNode("div", { class: "card_image the_dumpling_tree" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_7,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "The Dumpling Tree"),
                        _createElementVNode("p", null, "Family-run Yunnan restaurant"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Noodles & dumplings"),
                          _createElementVNode("p", null, "For sharing")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Cambridge')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "tzatziki" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Office lunches",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[15] || (_cache[15] = [
                      _createElementVNode("div", { class: "card_image tzatziki" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_8,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Tzatziki"),
                        _createElementVNode("p", null, "Authentic Greek food"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Souvlakis & Skepastis"),
                          _createElementVNode("p", null, "Buffets")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Cambridge')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "taste_of_cambridge" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Office lunches",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[16] || (_cache[16] = [
                      _createElementVNode("div", { class: "card_image taste_of_cambridge" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_9,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Taste of Cambridge"),
                        _createElementVNode("p", null, "Handmade falafel wraps"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Falafel Wraps"),
                          _createElementVNode("p", null, "Healthy")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Cambridge')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "hot_numbers" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Office lunches",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[17] || (_cache[17] = [
                      _createElementVNode("div", { class: "card_image hot_numbers" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_10,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Hot Numbers"),
                        _createElementVNode("p", null, "Cambridge’s finest roastery"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Fresh baguettes"),
                          _createElementVNode("p", null, "Bundles")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.cityName === 'Cambridge')
            ? (_openBlock(), _createBlock(_component_Slide, { key: "boxed_events" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: "Office lunches",
                    class: "custom-cursor-hover",
                    onClick: _ctx.scrollToTop
                  }, {
                    body: _withCtx(() => _cache[18] || (_cache[18] = [
                      _createElementVNode("div", { class: "card_image boxed_events" }, [
                        _createElementVNode("img", {
                          class: "card_logo",
                          src: _imports_11,
                          alt: ""
                        })
                      ], -1),
                      _createElementVNode("div", { class: "card_content" }, [
                        _createElementVNode("h3", null, "Boxed Events"),
                        _createElementVNode("p", null, "Artisan catering fit for any occasion"),
                        _createElementVNode("div", { class: "card_food_containers" }, [
                          _createElementVNode("p", null, "Flexible, customisable menus")
                        ])
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["breakpoints"])
    ]),
    _cache[25] || (_cache[25] = _createElementVNode("img", {
      class: "divider_image",
      src: _imports_14,
      alt: ""
    }, null, -1)),
    _createVNode(_component_ThePerfectFood, {
      title: "The perfect lunches for your office",
      subtitle: `Foodstuff makes office lunch deliveries easy, healthy and delicious. Choose from the highest quality independent restaurants and corporate caterers in ${_ctx.cityName} and make sure your team loves their lunch every time. Lunch delivered the office with no stress.`,
      onOrderNowClick: _ctx.orderNow,
      onPlanSomethingBiggerClick: _ctx.goToEnquiryPage
    }, null, 8, ["subtitle", "onOrderNowClick", "onPlanSomethingBiggerClick"]),
    _createVNode(_component_BuiltForEase, { onHereClick: _ctx.openFreshchat }, null, 8, ["onHereClick"]),
    _cache[26] || (_cache[26] = _createElementVNode("div", { class: "divider_list_of_logos" }, [
      _createElementVNode("img", {
        class: "hide_mobile",
        src: _imports_15,
        alt: ""
      }),
      _createElementVNode("img", {
        class: "hide_desktop",
        src: _imports_16,
        alt: ""
      })
    ], -1)),
    _createVNode(_component_SustainableAndEthical),
    _createVNode(_component_HowItWorks, { title: "Ordering lunch to the office" }, {
      "pick-from": _withCtx(() => _cache[22] || (_cache[22] = [
        _createElementVNode("p", null, [
          _createTextVNode("Pick from our "),
          _createElementVNode("b", null, "curated list"),
          _createTextVNode(" of corporate lunch restaurants in Cambs/Bristol")
        ], -1)
      ])),
      _: 1
    }),
    _createElementVNode("div", _hoisted_7, [
      _cache[24] || (_cache[24] = _createElementVNode("p", null, "Got a few questions? Give our team a shout", -1)),
      _createVNode(_component_app_button, {
        slim: "",
        white: "",
        onClick: _ctx.openFreshchat
      }, {
        default: _withCtx(() => _cache[23] || (_cache[23] = [
          _createElementVNode("img", {
            src: _imports_17,
            alt: ""
          }, null, -1),
          _createElementVNode("p", null, "Chat to the team", -1)
        ])),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_EverybodyWins)
  ]))
}