import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/landing/main_list_of_logos.png'
import _imports_1 from '../assets/landing/corner_meals/landing_left_upper_corner.jpg'
import _imports_2 from '../assets/landing/corner_meals/landing_right_upper_corner.jpg'
import _imports_3 from '../assets/landing/corner_meals/landing_left_lower_corner.jpg'
import _imports_4 from '../assets/landing/corner_meals/landing_right_lower_corner.jpg'
import _imports_5 from '../assets/landing/carousel_left.png'
import _imports_6 from '../assets/landing/carousel_right.png'
import _imports_7 from '../assets/landing/variants/main-divider.png'
import _imports_8 from '../assets/landing/divider_list_of_logos.png'
import _imports_9 from '../assets/landing/divider_list_of_logos_mobile.png'
import _imports_10 from '../assets/order_tracking/message.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "header_section" }
const _hoisted_3 = { class: "occasions_section" }
const _hoisted_4 = { class: "sub_title" }
const _hoisted_5 = { class: "divider_contact" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_TickerTape = _resolveComponent("TickerTape")!
  const _component_WeSupportLocal = _resolveComponent("WeSupportLocal")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Slide = _resolveComponent("Slide")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_Carousel = _resolveComponent("Carousel")!
  const _component_ThePerfectFood = _resolveComponent("ThePerfectFood")!
  const _component_BuiltForEase = _resolveComponent("BuiltForEase")!
  const _component_SustainableAndEthical = _resolveComponent("SustainableAndEthical")!
  const _component_HowItWorks = _resolveComponent("HowItWorks")!
  const _component_EverybodyWins = _resolveComponent("EverybodyWins")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "title" }, [
        _createTextVNode(" Heroic food"),
        _createElementVNode("br"),
        _createTextVNode(" for your office ")
      ], -1)),
      _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "sub_title" }, " Whether it's an office lunch, a client meeting, or a large-scale event we'll do the heavy lifting. Choose from a selection of Cambridge, Bristol and London's best restaurants and take your office experiences from zero to hero. ", -1)),
      _createVNode(_component_app_button, {
        class: "order_button",
        slim: "",
        onClick: _ctx.orderNow
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IconServices, { icon: "upload" }),
          _cache[1] || (_cache[1] = _createElementVNode("p", null, "Show me food", -1))
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createElementVNode("p", {
        class: "how-does-it-work-question custom-cursor-hover",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.scrollToHowItWorks && _ctx.scrollToHowItWorks(...args)))
      }, "How does it work?"),
      _cache[4] || (_cache[4] = _createStaticVNode("<p class=\"quote\" data-v-56cda3a8> “<b data-v-56cda3a8>Food was amazing, great variety and spot on recommendations - you guys are professionals!</b>” - Apple </p><img id=\"main_list_of_logos\" src=\"" + _imports_0 + "\" alt=\"\" data-v-56cda3a8><img id=\"left_upper_corner_meal\" src=\"" + _imports_1 + "\" alt=\"\" data-v-56cda3a8><img id=\"right_upper_corner_meal\" src=\"" + _imports_2 + "\" alt=\"\" data-v-56cda3a8><img id=\"left_lower_corner_meal\" src=\"" + _imports_3 + "\" alt=\"\" data-v-56cda3a8><img id=\"right_lower_corner_meal\" src=\"" + _imports_4 + "\" alt=\"\" data-v-56cda3a8>", 6))
    ]),
    _createVNode(_component_TickerTape),
    _createElementVNode("div", _hoisted_3, [
      _cache[8] || (_cache[8] = _createElementVNode("h1", { class: "section_title" }, "Food for all occasions", -1)),
      _createElementVNode("h2", _hoisted_4, [
        _cache[5] || (_cache[5] = _createTextVNode(" From team lunches and breakfasts to client meetings and full-blown events ")),
        _createVNode(_component_WeSupportLocal, {
          color: _ctx.ham,
          width: 100,
          height: 100
        }, null, 8, ["color"])
      ]),
      _createVNode(_component_Carousel, {
        class: "occasions_carousel",
        breakpoints: _ctx.breakpoints,
        "wrap-around": true
      }, {
        addons: _withCtx(() => [
          _createVNode(_component_Navigation, null, {
            prev: _withCtx(() => _cache[6] || (_cache[6] = [
              _createElementVNode("img", {
                class: "carousel_nav_arrow",
                src: _imports_5,
                alt: ""
              }, null, -1)
            ])),
            next: _withCtx(() => _cache[7] || (_cache[7] = [
              _createElementVNode("img", {
                class: "carousel_nav_arrow",
                src: _imports_6,
                alt: ""
              }, null, -1)
            ])),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Slide, { key: "office_lunches" }, {
            default: _withCtx(() => [
              _createVNode(_component_Card, {
                title: "Office lunches",
                text: "Hang out as a team with great food from Foodstuff, delivered to your office hassle-free.",
                imageName: "office_lunches",
                onFindOutMoreClick: _ctx.openFreshchat
              }, null, 8, ["onFindOutMoreClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_Slide, { key: "office_breakfasts" }, {
            default: _withCtx(() => [
              _createVNode(_component_Card, {
                title: "Office breakfasts",
                text: "Kick off the day with food that gets the team smiling and full of ideas.",
                imageName: "office_breakfasts",
                onFindOutMoreClick: _ctx.openFreshchat
              }, null, 8, ["onFindOutMoreClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_Slide, { key: "corporate_catering" }, {
            default: _withCtx(() => [
              _createVNode(_component_Card, {
                title: "Corporate catering",
                text: "We’ll make sure the food is taken care of so you can focus on getting stuff done.",
                imageName: "corporate_catering",
                onFindOutMoreClick: _ctx.openFreshchat
              }, null, 8, ["onFindOutMoreClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_Slide, { key: "daily_office_meals" }, {
            default: _withCtx(() => [
              _createVNode(_component_Card, {
                title: "Daily office meals",
                text: "Supercharge your team with healthy, daily meals, delivered to your office. ",
                imageName: "daily_office_meals",
                onFindOutMoreClick: _ctx.openFreshchat
              }, null, 8, ["onFindOutMoreClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_Slide, { key: "bespoke_events" }, {
            default: _withCtx(() => [
              _createVNode(_component_Card, {
                title: "Bespoke events",
                text: "We pride ourselves on being food fixers. Anything you need, we’ve got you covered.",
                imageName: "bespoke_events",
                onFindOutMoreClick: _ctx.openFreshchat
              }, null, 8, ["onFindOutMoreClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["breakpoints"])
    ]),
    _cache[12] || (_cache[12] = _createElementVNode("img", {
      class: "divider_image",
      src: _imports_7,
      alt: ""
    }, null, -1)),
    _createVNode(_component_ThePerfectFood, {
      title: "The perfect food for your office",
      subtitle: "Improve your office eating experiences with Foodstuff. We're partnered with 100+ top-notch restaurants and caterers in Cambridge, Bristol and London, ensuring a diverse offering adaptable to every office occasion, diet, and budget. Order now and get the best office meals for your team.",
      onOrderNowClick: _ctx.orderNow,
      onPlanSomethingBiggerClick: _ctx.goToEnquiryPage
    }, null, 8, ["onOrderNowClick", "onPlanSomethingBiggerClick"]),
    _createVNode(_component_BuiltForEase, { onHereClick: _ctx.openFreshchat }, null, 8, ["onHereClick"]),
    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "divider_list_of_logos" }, [
      _createElementVNode("img", {
        class: "hide_mobile",
        src: _imports_8,
        alt: ""
      }),
      _createElementVNode("img", {
        class: "hide_desktop",
        src: _imports_9,
        alt: ""
      })
    ], -1)),
    _createVNode(_component_SustainableAndEthical),
    _createVNode(_component_HowItWorks, { title: "How it works" }, {
      "pick-from": _withCtx(() => _cache[9] || (_cache[9] = [
        _createElementVNode("p", null, [
          _createTextVNode(" Choose from a "),
          _createElementVNode("b", null, "selection of Cambridge, Bristol and London's best restaurants"),
          _createTextVNode(" and take your office experiences from zero to hero ")
        ], -1)
      ])),
      _: 1
    }),
    _createElementVNode("div", _hoisted_5, [
      _cache[11] || (_cache[11] = _createElementVNode("p", null, "Got a few questions? Give our team a shout", -1)),
      _createVNode(_component_app_button, {
        slim: "",
        white: "",
        onClick: _ctx.openFreshchat
      }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [
          _createElementVNode("img", {
            src: _imports_10,
            alt: ""
          }, null, -1),
          _createElementVNode("p", null, "Chat to the team", -1)
        ])),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_EverybodyWins)
  ]))
}