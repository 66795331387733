import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"
import _imports_0 from '../assets/order_tracking/message.png'


const _hoisted_1 = { class: "global-border-bottom global-navbar" }
const _hoisted_2 = { class: "nav-wrapper" }
const _hoisted_3 = { class: "nav-buttons" }
const _hoisted_4 = {
  key: 4,
  class: "mobile-nav-partner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_ButtonOrderTracker = _resolveComponent("ButtonOrderTracker")!
  const _component_AuthButtons = _resolveComponent("AuthButtons")!
  const _component_IconService = _resolveComponent("IconService")!
  const _component_PartnerDropdown = _resolveComponent("PartnerDropdown")!
  const _component_NavigationDropdown = _resolveComponent("NavigationDropdown")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/",
        name: "homepage",
        "aria-label": "homepage"
      }, {
        default: _withCtx(() => [
          (_ctx.logoTextHidden)
            ? (_openBlock(), _createBlock(_component_Logo, { key: 0 }))
            : (_openBlock(), _createBlock(_component_Logo, {
                key: 1,
                text: ""
              }))
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.user && !_ctx.isMobileView)
          ? (_openBlock(), _createBlock(_component_app_button, {
              key: 0,
              class: "work-with-us-btn",
              onClick: _ctx.goToVendorPage
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("p", null, "Work with us", -1)
              ])),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (!_ctx.isMobileView)
          ? (_openBlock(), _createBlock(_component_app_button, {
              key: 1,
              class: "plan-something-btn",
              onClick: _ctx.goToEnquiryPage
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("p", null, "Plan something bigger", -1)
              ])),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (!_ctx.isMobileView)
          ? (_openBlock(), _createBlock(_component_app_button, {
              key: 2,
              class: "chat-support-btn",
              onClick: _ctx.openFreshchat
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createElementVNode("p", null, "Chat to live support", -1)
              ])),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_ButtonOrderTracker),
        _createVNode(_component_router_link, {
          to: _ctx.goTo(),
          custom: ""
        }, {
          default: _withCtx(({ navigate }) => [
            (_ctx.dishes.length > 0 && _ctx.user)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 0,
                  onClick: navigate,
                  mini: "",
                  square: "",
                  white: ""
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("b", null, _toDisplayString(_ctx.numberOfBasketItems(_ctx.dishes)), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["to"]),
        (_ctx.isMobileView && _ctx.user)
          ? (_openBlock(), _createBlock(_component_app_button, {
              key: 3,
              onClick: _ctx.openFreshchat,
              mini: "",
              square: "",
              white: ""
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: ""
                }, null, -1)
              ])),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_AuthButtons, { isMobileView: _ctx.isMobileView }, null, 8, ["isMobileView"]),
        (!_ctx.user && _ctx.isMobileView)
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_app_button, {
                class: "work-with-us-btn-mobile",
                mini: "",
                square: "",
                white: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dropdownPartner = !_ctx.dropdownPartner)),
                "aria-label": "dropdown"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_IconService, { icon: "hamburger" })
                ]),
                _: 1
              }),
              (_ctx.dropdownPartner)
                ? (_openBlock(), _createBlock(_component_NavigationDropdown, {
                    key: 0,
                    class: "partner-dropdown"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PartnerDropdown, {
                        onPush: _ctx.push,
                        isMobileView: ""
                      }, null, 8, ["onPush"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])), [
              [_directive_click_outside, () => (_ctx.dropdownPartner = false)]
            ])
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}